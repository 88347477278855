import React from 'react';

// Lib
import parse from 'html-react-parser';


//Services
import i18n from '../i18n';

const BottomLinks =  () => {

  let handleGetStartedAction = () => {

    window.location.assign(window.MOBILE_SCHEME + `login?idp=app-approval`);
    
  }

  return (
    <div className="bottom-container">
      <div className="bottom-links">
        {
          parse(i18n.t("footer_pp_and_tou_links", { tou_url: window.GLOBAL_PATH+"terms-of-use", pp_url: window.GLOBAL_PATH+"privacy-policy" }))
        }
        
      </div>
      {window.config.apple_approval_identifiers && window.THEME && window.config.apple_approval_identifiers.filter((element) => { return window.THEME.includes(element)}).length > 0 &&
        <div className="bottom-links apple-review">
          <button onClick={handleGetStartedAction}>login for Apple review</button>
        </div>
      }
    </div>

  )
}

export default BottomLinks;